import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, UrlHandlingStrategy } from '@angular/router';
import { JWT_OPTIONS } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ApiModule } from 'libs/admin/data-access/services/generated/api.module';
import {
  Configuration,
  ConfigurationParameters,
} from 'libs/admin/data-access/services/generated/configuration';
import * as fromAuth from 'libs/shared/data/stores/auth/auth.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { MessageService } from 'primeng/api';

import { AuthInterceptor } from '@offconon/admin/features/auth';
import { ApiModule as CoreApiModule } from '@offconon/shared/features/generated-api';
import { COUNTRY_FEATURE_KEY } from '@offconon/shared/ui/form-elements/country-select-list';
import {
  CustomUrlHandlingStrategy,
  GlobalErrorHandlerService,
  initLanguageFactory,
  LanguageService,
} from '@offconon/shared/utils/services';
import { SharedUtilsTranslateModule } from '@offconon/shared/utils/translate';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app-routing.routes';
import { reducers } from './app/root-reducer';
import { environment } from './environments/environment';

export function apiCoreConfigFactory(): any {
  const params: ConfigurationParameters = {
    basePath: environment.coreApi,
  };
  return new Configuration(params);
}

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.adminApi,
  };
  return new Configuration(params);
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['auth', COUNTRY_FEATURE_KEY], rehydrate: true })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  bootstrapApplication(AppComponent, {
    providers: [
      {
        provide: APP_INITIALIZER,
        useFactory: initLanguageFactory,
        deps: [CustomUrlHandlingStrategy],
        multi: true,
      },
      { provide: UrlHandlingStrategy, useClass: CustomUrlHandlingStrategy },
      importProvidersFrom(
        CommonModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreModule.forFeature(fromAuth.AUTH_FEATURE_KEY, fromAuth.authReducer),
        StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
          connectInZone: true,
        }),
        EffectsModule.forRoot([]),

        ApiModule.forRoot(apiConfigFactory),
        CoreApiModule.forRoot(apiCoreConfigFactory),

        SharedUtilsTranslateModule,
        TimeagoModule.forRoot({
          intl: { provide: TimeagoIntl, useClass: MyIntl },
          formatter: {
            provide: TimeagoFormatter,
            useClass: TimeagoCustomFormatter,
          },
        }),
      ),
      {
        provide: LOCALE_ID,
        deps: [LanguageService],
        useFactory: (languageService: LanguageService) => {
          return languageService.getLanguageAndRegisterLocaleData();
        },
      },
      MessageService,
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: ErrorHandler, useClass: GlobalErrorHandlerService },

      { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
      { provide: UrlHandlingStrategy, useClass: CustomUrlHandlingStrategy },
      LanguageService,
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi(), withFetch()),
      provideRouter(appRoutes),
    ],
  }).catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
  console.log('App is ready');

  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
export class MyIntl extends TimeagoIntl {}
